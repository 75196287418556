<template>
  <div
      class="pageContainer app-container studentContainer"
      style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>填写记录</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="formData.type" style="" @tab-click="changeTab1">
      <el-tab-pane label="" name="0">
        <div slot="label" class="flexStart">
          <i class="el-icon-user-solid" style="font-size: 18px;"></i>
          <span style="font-size: 18px;margin-left: 10px; font-weight: 700;">学生</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="" name="1">
        <div slot="label" class="flexStart">
          <i class="el-icon-office-building" style="font-size: 18px;"></i>
          <span style="font-size: 18px;margin-left: 10px; font-weight: 700;">企业</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="" name="2">
        <div slot="label" class="flexStart">
          <i class="el-icon-s-custom" style="font-size: 18px;"></i>
          <span style="font-size: 18px;margin-left: 10px; font-weight: 700;">家长</span>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-row style="margin-block: 10px">
      <el-input  v-if="formData.type==0" v-model="formData.user_name" size="small" placeholder="姓名" style="max-width: 200px; margin-right: 10px;"/>
      <el-input  v-if="formData.type==1" v-model="formData.user_name" size="small" placeholder="企业名称" style="max-width: 200px; margin-right: 10px;"/>
      <el-select v-model="formData.plan_name" filterable size="small" placeholder="问卷计划">
        <el-option v-for="item in planList"
                   :key="item.id"
                   :label="item.plan_name"
                   :value="item.id">
        </el-option>
      </el-select>

      <!--      <el-input v-model="formData.plan_name" size="small" placeholder="问卷计划" style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>-->
      <!--      <el-select-->
      <!--        v-model="formData.type"-->
      <!--        placeholder="请选择发送类型"-->
      <!--        style="max-width: 200px; margin-right: 10px; margin-left: 10px"-->
      <!--      >-->
      <!--        <el-option label="学生" value="0"></el-option>-->
      <!--        <el-option label="家长" value="1"></el-option>-->
      <!--        <el-option label="企业" value="2"></el-option>-->
      <!--      </el-select>-->
      <el-select size="small" v-model="formData.send_type" placeholder="请选择发送方式" style="max-width: 200px; margin-right: 10px; margin-left: 10px">
        <el-option label="小程序" value="0"></el-option>
        <el-option label="邮箱" value="1"></el-option>
        <el-option label="短信" value="2"></el-option>
      </el-select>
      <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
      <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
    </el-row>


    <el-table stripe :data="dataList" style="width: 100%; margin-bottom: 20px; color: #000" border size="mini">
      <el-table-column v-if="formData.type==0" prop="stu_name" label="姓名" min-width="120"></el-table-column>
      <el-table-column v-if="formData.type==1" prop="user_name" label="企业名称" min-width="120"></el-table-column>
      <el-table-column v-if="formData.type==0" prop="start_year" label="入学年份" min-width="80"></el-table-column>
      <el-table-column v-if="formData.type==0" prop="departments_name" label="院（系）" width="120"></el-table-column>
      <el-table-column v-if="formData.type==0" prop="grade_name" label="年级" width="120"></el-table-column>
      <el-table-column v-if="formData.type==0" prop="class_name" label="班级" width="100"></el-table-column>
      <el-table-column prop="plan_name" label="问卷计划" min-width="120"></el-table-column>
      <el-table-column prop="ftype" label="作答方式" min-width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.ftype == 0">小程序</span>
          <span v-if="scope.row.ftype == 1">邮箱</span>
          <span v-if="scope.row.ftype == 2">短信</span>
        </template>
      </el-table-column>
      <el-table-column prop="cdate" label="作答时间" min-width="120"></el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total, prev, pager, next"
          :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      count: 0,
      current_page: 1,
      per_page: 20,
      activeName: "td",
      dataList: [],
      formData: {
        type:'0'
      },
      planList:[],
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
    this.getPlan()
  },
  methods: {
    getlist() {
      this.$http
          .post("api/fill_log_list", {
            page: this.current_page,
            pagesize: this.per_page,
            type: this.formData.type,
            send_type: this.formData.send_type,
            user_name: this.formData.user_name,
            plan_name: this.formData.plan_name,
          })
          .then((res) => {
            this.dataList = res.data.data;
            this.count = res.data.page.count;
            this.current_page = res.data.page.current_page;
            this.per_page = res.data.page.per_page;
          });
    },
    getPlan() {
      this.planList = []
      this.$http.post("api/by_ivst_plan_list", {page: 1, pagesize: 1000000,}).then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.planList = res.data.data;
        }
      });
    },
    changeTab() {
      this.getlist();
    },
    changeTab1(){
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {
        type:'0'
      };
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
  },
};
</script>


